@import "../../assets/scss/colors";
@import "../../shared/shared.themes.colors/colors.module.scss";

.Search-Page {
  .status-bar {
    justify-content: baseline;
    align-items: baseline;
    flex-direction: column;
    position: relative;
    z-index: 100000;

    .controls {
      width: 100%;
      display: flex;
      flex-direction: column;
      .Insights-ui__btn,
      .Insights-ui__loading {
        margin: 0;
      }
    }

    .filters {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      .select-group,
      .input-container {
        min-width: 160px;
        margin-right: 16px;
      }
    }

    .input-term {
      margin-top: 12px;
      width: 100%;
    }

    .counters-container {
      display: flex;
      align-items: flex-end;

      min-width: 260px;
      font-size: 12px;
      justify-content: space-between;

      .counter {
        border: 1px solid #ccc;
        padding: 6px;
        border-radius: 4px;
        margin: 12px 8px 0 0;

        span {
          font-weight: bold;
          margin-right: 8px;
        }

        &.negative {
          span {
            color: rgba(255, 150, 150, 1);
          }
        }

        &.positive {
          span {
            color: rgba(100, 228, 100, 1);
          }
        }
      }

      .reset-counter {
        cursor: pointer;
        color: #aaa;
        appearance: none;
        border-radius: 4px;
        padding: 6px;
        opacity: 0;
        transition-delay: 0.4s;
        pointer-events: none;
      }

      &:hover {
        .reset-counter {
          pointer-events: all;
          opacity: 1;
        }
      }
    }
  }

  .page-results {
    padding: 20px;
  }
}

.search-videos-grid {
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  grid-gap: 16px;
  margin-top: 12px;
}

.VideoCard {
  width: 300px;
  height: 436px;
  background-color: white;
  overflow: hidden;
  position: relative;

  .thumbnail {
    border-radius: 8px;
    background-color: #000;
    position: relative;
    z-index: 1;
    width: 100%;
    height: 168px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .icon {
    width: 32px;
    height: 32px;
    border: 1px solid $base_color2;
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    padding: 4px 6px;
    fill: #fff;
    border-radius: 8px 0;

    &.yt {
      background-color: $youtube;
    }

    &.fb {
      background-color: $facebook;
    }

    &.in {
      background-color: $instagram;
    }

    &.tw {
      background-color: $twitch;
    }
  }

  .content-info {
    position: relative;
    z-index: 2;

    .title {
      margin-top: 8px;
      font-size: 14px;
      color: #494b4f;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow-y: scroll;
      height: 50px;
    }

    .tags {
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-size: 14px;
      overflow-y: scroll;
      scrollbar-width: 1px;
      height: 100px;

      .tag {
        display: inline-block;
        font-size: 12px;
        padding: 3px 5px;
        margin: 0 4px 4px 0;
        border: 1px solid $gray;
        color: $gray;
        border-radius: 4px;
      }
    }

    .post-creator {
      display: flex;
      align-items: center;
      margin: 8px 0 20px;

      .post-creator-thumbnail {
        width: 24px;
        height: 24px;
        margin-right: 11px;

        > img,
        > svg {
          border-radius: 50%;
          border-radius: 50%;
        }
      }

      .post-creator-title {
        font-size: 14px;
        color: $primary;
      }
    }

    .tags,
    .title {
      &::-webkit-scrollbar {
        width: 5px !important;
      }
    }
  }

  .card-controls {
    height: 56px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    .divider {
      width: 1px;
      height: 100%;
      background-color: $gray_light2;
    }

    &:hover .divider {
      width: 0;
    }

    .button {
      flex-grow: 1;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: relative;
      background-color: #f5f7fb;
      border-style: solid;
      border-width: 1px;
      border-color: $gray_light2;
      transition: background-color 160ms, border-color 160ms;

      svg path {
        transition: stroke 160ms;
        stroke: $gray_dark1 !important;
      }

      &.cancel {
        border-right-width: 0;

        &:hover {
          background-color: #ffdbe4;
          border-color: $danger;
          border-right-width: 1px;

          svg path {
            stroke: $danger !important;
          }
        }
      }

      &.confirm {
        border-left-width: 0;

        &:hover {
          background-color: #c7faf7;
          border-color: $success;
          border-left-width: 1px;

          svg path {
            stroke: $success !important;
          }
        }
      }

      &.disabled {
        background-color: $gray_light2;
        cursor: not-allowed;

        svg path {
          stroke: $gray !important;
        }

        &::after {
          display: none;
          content: attr(aria-label);
          position: absolute;
          z-index: 10;
          top: 50%;
          transform: translateY(-50%);
          color: $gray_dark2;
        }

        &:hover {
          background-color: $gray_light2;
          border-color: $gray_light2;

          svg path {
            stroke: $gray_light2 !important;
          }

          &::after {
            display: inline-block;
          }
        }
      }
    }
  }
}
