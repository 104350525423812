@import '../../shared/shared.themes.colors/colors.module.scss';

@mixin buttonStates($main_color, $hover_color, $disabled_color) {
  &.default {
    background-color: $main_color;
    border-color: $main_color; 
    &:hover {
      background-color: $hover_color;
    }
    &.with-icon {
      svg {
        fill: #fff;
      }
    }
  }

  &[disabled], &[disabled]:hover {
    background-color: $disabled_color;
    border-color: $disabled_color;
    cursor: not-allowed;
  }

  &:hover {
    border-color: $hover_color;
  }


  &.outline {
    background-color: transparent;
    color: $main_color;
    &.with-icon {
      svg {
        fill: $main_color;
      }
    }
    &:hover {
      color: $hover_color;
      &.with-icon {
        svg {
          fill: $hover_color;
        }
      }
    }
  }

  &.link {
    background-color: transparent;
    color: $main_color;
    border-color: transparent;
    padding: 6px 12px;
    &.with-icon {
      padding: 6px 12px 6px 8px;
      svg {
        fill: $main_color;
      }
    }
    &:hover {
      color: $hover_color;
      &.with-icon {
        svg {
          fill: $hover_color;
        }
      }
    }
  }
}

.Insights-ui__btn {
  font-family: $sans;
  border: none;
  padding: 6px 16px;
  border-radius: 4px;
  cursor: pointer;
  box-sizing: border-box;
  color: #fff;
  font-size: 0.875rem;
  line-height: 24px;
  border-width: 1px;
  border-style: solid;
  // margin: 0;
  margin: 6px;
  outline: none;
  display: inline-flex;
  align-items: center;
  
  &.with-icon {
    padding-left: 12px; 
    svg {
      margin: 0 8px 0 0;
    }
  }

  &.fluid {
    width: 100%;
  }

  &.primary {
    @include buttonStates($primary, $primary_dark1, $gray_light2);
  }
  
  &.highlighted {
    @include buttonStates($highlight, $highlight_dark1, $gray_light2);
  }
  
  &.faded {
    @include buttonStates($gray_dark2, $primary_dark2, $gray_light2);
  }
}
