@import "../../shared/shared.themes.colors/colors.module.scss";

.SubtopicEditHeader {
  .top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .inputs,
    .buttons {
      display: flex;
      align-items: flex-end;

      > * + * {
        margin-left: 20px;
      }
    }

    .inputs {
      flex-grow: 1;
      margin-right: 20px;

      .name-input {
        width: 100%;
        max-width: 440px;
        min-width: 200px;
      }
    }

    .buttons {
      .reset-button {
        &[disabled] {
          color: $gray_light2;
        }
      }

      .save-button {
        width: 126px;
        justify-content: center;
      }
    }

    .select-group {
      width: 180px;
      flex-shrink: 0;
    }
  }

  .middle {
    .term-input {
      margin-bottom: 20px;
    }
  }

  .bottom {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .tags {
      .tag-title {
        display: block;
        font-size: 12px;
        color: $gray_dark1;
        margin-bottom: 8px;
      }

      .tag-list {
        font-size: 14px;
        font-weight: bold;
        color: $primary;
      }
    }

    .delete-subtopic {
      margin-left: 20px;
      flex-shrink: 0;

      .Insights-ui__btn {
        .Insights-ui__icon {
          transform: translateY(-2px);
        }
      }
    }
  }
}
