@import '../../shared/shared.themes.colors/colors.module.scss';

.Blocklist-Page{
    .container-search{
        align-items: baseline;
        flex-direction: column;
        position: relative;
        z-index: 100000;
        padding: 16px;
        display: flex;
        flex-wrap: wrap;
        background-color: #fff;
        .blocked-list-wrapper {
            padding: 22px;
        }

        .container-result {
          margin-top: 20px;
          width: 100%;
          display: flex;
          .loading {
            text-align: center;
            margin: 0 auto;
          }
          .CardCreator {
            width: 20%;
            margin-right: 20px;
          }
          .error{
            align-self: center;
          }
          .container-add-creator-blocklist {
            align-self: center;
            width: 100%;
            .filters{
              margin-top: 40px;
              display: flex;
              width: 100%;
              .select-group{
                margin-right: 16px;
                width: 20%;
                &:nth-of-type(2){
                  width: 60%;
                }
              }
            }
          }
        }
      }
      .error, .success {
        text-align: center;
        font-weight: bold;
        color: $primary;
      }
      .success {
        margin-bottom: 2em;
      }
      .search-input {
        width: 100%
      }

      .result-container{
        width: 100%;
        .count{
          margin-bottom: 1em;
        }

      .filter-container{
        display: flex;
        flex-direction: row;
        width: 50%;
        margin-left: auto;
        flex-wrap: wrap;
        justify-content: flex-end;

        .search-result-input{
          width: 35%;
          align-self: flex-end;
          margin-right: 1em;
        }

        .select-group {
          width: 30%;
          margin-right: 1em;
          &:last-child{
             margin-right: 0;
           }
        }
      }
      }
}

.result-container {
  --topic-list-column-template: 1fr 1fr 2fr;
  padding: 20px;
}

.creator-list-headers {
  h6 {font-size: 12px;}

  border-radius: 4px;
  display: grid;
  background-color: #fff;
  margin-bottom: 12px;
  grid-template-columns: var(--topic-list-column-template);
  & > div {
    padding: 8px;
  }
}

.creator-list-item {
  background-color: #fff;
  margin-bottom: 3px;
  display: grid;
  color: $base_color1;
  grid-template-columns: var(--topic-list-column-template);
  & > div {
    border-radius: 4px;
    width: 100%;
    padding: 8px;
    border-right: 3px solid $background;
  }
  .flex {
    display: flex;
  }

  .topics-item, .creator-item, .languages-item {
    p {
      font-size: 14px;
      align-self: center;
    }
    img {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      margin-right: 1em;
    }
    text-transform: capitalize;
  }

}
