@import "../../shared/shared.themes.colors/colors.module.scss";

.select-group {
  position: relative;

  .select-label {
    font-size: 12px;
    color: rgba(73, 75, 78, 0.9);
  }

  .filter {
    margin-left: 0;
  }

  .select-icon-group {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 4px;

    &:focus-within {
      border-color: $primary;
    }

    .filter {
      width: 100%;

      & > div {
        border: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    .select-icon {
      padding-left: 6px;
      height: 100%;
      min-width: 36px;
    }
  }

  .reactSelect-sublabel {
    color: $gray_dark1;
    font-size: 12px;

    &.selected {
      color: $base_color2;
    }
  }

  //fix react-bug
  div[class*="-menu"] {
    bottom: auto !important;
  }
}
