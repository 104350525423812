@import "../../assets/scss/vars";

.root {
  padding: $spacing3;
}

.pageHeader {
  display: flex;
  justify-content: space-between;
}

.inputs {
  display: flex;

  > * {
    width: 260px;

    + * {
      margin-left: $spacing3;
    }
  }
}
