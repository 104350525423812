@import "../../shared/shared.themes.colors/colors.module.scss";

.SubtopicEditPage {
  .page-header,
  .page-content {
    padding: 20px;
  }

  .page-header {
    border-bottom: 1px solid $gray_light2;
  }

  .Insights-ui__input > *,
  .Insights-ui__btn {
    margin: 0;
  }
}
