@import "../../shared/shared.themes.colors/colors.module.scss";

.TopicEditHeader {
  .top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .inputs,
    .buttons {
      display: flex;
      align-items: flex-end;

      > * + * {
        margin-left: 20px;
      }
    }

    .inputs {
      flex-grow: 1;
      margin-right: 20px;

      .name-input {
        width: 100%;
        max-width: 440px;
        min-width: 200px;
      }
    }

    .buttons {
      .reset-button {
        &[disabled] {
          color: $gray_light2;
        }
      }

      .save-button {
        width: 126px;
        justify-content: center;
      }
    }

    .select-group {
      width: 180px;
      flex-shrink: 0;
    }
  }
}
