.input-container {
  background: #fff;
  align-items: center;

  .input-grid {
    border: 1px solid hsl(0, 0%, 80%);
    padding: 13px;
    height: 38px;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    align-items: center;

    &.clipboard {
      padding-right: 0;

      .clipboard-button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  .icon-container {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }

  .input-column {
    flex: 1;

    input {
      width: 100%;
    }
  }

  .input-label {
    display: block;
    font-size: 12px;
    color: rgba(73, 75, 78, 0.9);
  }

  input {
    border: 0;
    padding-right: 15px;

    &:disabled {
      opacity: 0.3;
    }

    &::placeholder {
      font-weight: normal;
      color: rgba(73, 75, 78, 0.9);
      font-size: 0.875em;
    }
  }
}
