@import "../../shared/shared.themes.colors/colors.module.scss";

.TabPage {
  .tab-header {
    padding: 16px;
  }

  .tabs-container {
    display: flex;
    padding: 16px 16px 4px;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    background: $base_color2;

    .tab-link {
      color: $gray;

      + .tab-link {
        margin-left: 32px;
      }

      &::after {
        position: absolute;
        content: "";
        left: 0;
        top: calc(100% + 2px);
        width: 0;
        height: 2px;
        background: currentColor;
        transition: width 200ms;
        transition-delay: 200ms;
      }

      &.active {
        position: relative;
        color: $primary;

        &::after {
          width: 32px;
        }
      }
    }
  }
}
