.player-component {

    background-color:#000;
    cursor:pointer;
  
    .player-wrapper {
      margin: 0 auto;
      position: relative;
      max-width: 100%;
      min-width: 200px;
    }

    .provider-player {
      width: 100%;
    }
  }
  