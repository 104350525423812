@import "../../assets/scss/vars";

.centered-main-container {
  height: 100vh;
  display: flex;
  align-items: center;

  .centered-columns {
    position: relative;
    text-align: center;
    padding: 72px 16px 16px;
    height: 100%;
    box-sizing: border-box;
    min-width: 330px;
    max-height: 555px;
    margin: 0 auto;

    @media only screen and (min-width: $mobile_break) {
      max-width: 400px;
    }

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h1.heading {
      font-size: 3em;
      margin: 0 0 5px;
    }

    .sub-heading {
      font-size: 1.5em;
    }

    h3.heading {
      font-size: 1.5em;
      margin-top: 0;
    }
  }
}
