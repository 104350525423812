@import '../../theme_vars.scss';

.step-card-container {
    position: relative;
    padding:16px 16px 100px;
    width: 390px;
    box-sizing: border-box;

    .title-and-instructions {
        margin-bottom:16px;

        h3 {
            margin:0;
            font-size: 16px;
        }
        small {
            color: rgba(73, 75, 78, 0.9);
            font-size: 12px;
        }
    }

    .action-area {

        position: absolute;
        left: 0;
        right: 0;
        bottom:0;

        border-top: 1px solid #E5E5E5;
        padding: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .btn {

            &:hover {
                background-color: rgba(0,0,0,0.1);
            }

            &.hidden {
                visibility: hidden;
                display: block !important;
            }

            &.canNext {
                color: $brand1;
            }
        }

    }

    .progress-bullets {

        height: 5px;
        display: flex;

        .bullet {
            width: 5px;
            height: 5px;
            border-radius:50%;
            background: #B5B9BF;
            margin-left:7px;
            
            &.active {
                background-color: $brand1;
            }
            &:first-child {
                margin: 0;
            }
        }
    }
  
}