@import '../../shared/shared.themes.colors/colors.module.scss';

.Insights-ui__card {
  background-color: #fff;
  border-radius: 4px;
  
  &.hoverEffect {
    transition-duration: 0.2s;
    &:hover {
      box-shadow: $shadow_light;
    }
  }
}
