@import "../../shared/shared.themes.colors/colors.module.scss";
@import "../../assets/scss/vars";

.QueryDeleteModal {
  .Insights-ui__header {
    margin-top: 0;
  }

  .Insights-ui__btn {
    margin: 0;
  }

  .delete-title {
    margin-bottom: $spacing4;
  }

  .delete-actions {
    margin-top: $spacing6;

    display: flex;
    justify-content: flex-end;

    button {
      flex-grow: 1;
      justify-content: center;

      &:first-child {
        margin-right: $spacing4;
      }
    }
  }
}
