.project-card-container {
    .Insights-ui__header {
        text-transform: capitalize;
        margin:16px 16px 10px;
    }
    .footer-container {
        border-top: 1px #E5E5E5 solid ;
        padding: 12px;
        .Insights-ui__btn {
            margin: 0;
        }
    }
}