$primary_blue: #005263;
$primary_green: #029c96;
$primary_linear_gradient: linear-gradient(
  104.76deg,
  #007991 26.77%,
  #7cd6bb 94.18%
);

$primary_gray: rgba(196, 196, 196, 0.5);

$primary_text_dark: rgba(0, 0, 0, 0.87);
$secondary_text_dark: rgba(0, 0, 0, 0.54);
$disabled_text_dark: rgba(0, 0, 0, 0.38);

$primary_text_light: rgba(255, 255, 255, 1);
$secondary_text_light: rgba(255, 255, 255, 0.7);
$disabled_text_light: rgba(255, 255, 255, 0.5);

$shadow_popup: 0 0 6px -1px rgba(0, 0, 0, 0.35);
$shadow_light: 3px 3px 12px rgba(0, 0, 0, 0.08),
  0 0 6px 1px rgba(32, 32, 32, 0.02);

$post_gray: #a0a0a0;
$post_green: #7cd6bb;
$post_blue: #007992;
$post_red: #dd5e5e;
$post_yellow: #ffbc00;
$post_orange: #ff9045;

$purple: #765697;
$light_purple: #faf6ff;
$dark_purple: #17023f;

$youtube: #ff0000;
$facebook: #3b5999;
$instagram: #fcaf45;
$twitch: #874ff6;

$title_card: #494b4f;
