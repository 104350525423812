@import "../../shared/shared.themes.colors/colors.module.scss";

.TopicListPage {
  .page-header {
    display: flex;
    justify-content: space-between;
    padding: 20px;

    .create-new-button {
      flex-shrink: 0;

      .Insights-ui__btn {
        margin: 0;
      }
    }

    .topic-filters {
      flex-grow: 1;
      display: grid;
      align-items: end;
      grid-gap: 16px;
      grid-template-columns: auto 1fr 180px 260px;

      form input {
        height: 38px;
        margin: 0;
      }
    }
  }

  .page-content {
    padding: 20px;
  }

  .topics-list-header,
  .topics-item {
    grid-template-columns: 0.5fr 0.5fr 150px 150px 150px 150px;
  }
}
