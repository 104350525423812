@import "../../shared/shared.themes.colors/colors.module.scss";

.ClassificationPage {
  .search-container {
    padding: 16px;
    background: $base_color2;
  }

  .post-list-container {
    padding: 30px 16px;
  }
}

.ClassificationHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $base_color2;
  padding: 8px 16px 16px;

  .container-card-creator {
    padding: 0;
  }

  .classification-container {
    display: flex;
    align-items: center;

    .selected-count {
      margin-right: 100px;
      min-width: 100px;
    }

    .select-group {
      min-width: 200px;
      margin-right: 45px;
      transform: translateY(-6px);
      z-index: 10;
    }

    .cancel-classification[disabled] {
      color: $gray_light2;

      &:hover {
        background: $base_color2;
        border-color: $base_color2;
      }
    }
  }
}

.PostListHeader {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 16px;

  .actions {
    display: flex;
    gap: 32px;
    align-items: center;

    .action {
      display: flex;
      gap: 8px;

      .label {
        color: $gray;
      }

      .value {
        color: $gray_dark2;
      }
    }
  }

  .radio-input {
    display: flex;
    gap: 8px;
    cursor: pointer;
    height: 100%;

    &.disabled {
      cursor: not-allowed;

      .radio-label {
        color: $gray;
      }
    }

    &.active {
      .radio-button::after {
        width: 10px;
        height: 10px;
      }
    }
  }

  .radio-button {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid $gray;
    position: relative;

    &::after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      background: $primary;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: 100ms;
    }
  }
}

// .PostList {}

.PostListItem {
  max-height: 96px;
  display: grid;
  grid-template-columns: 192px auto 120px 96px;
  margin-bottom: 6px;
  overflow: hidden;

  &:last-child {
    margin-bottom: 0;
  }

  .thumbnail img {
    display: block;
    width: 100%;
    height: 96px;
    object-fit: cover;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px;

    .post-link {
      align-self: flex-start;
    }

    .lists {
      display: flex;

      p {
        font-size: 12px;

        + p {
          margin-left: 20px;
        }
      }
    }
  }

  .metric {
    margin: auto;
    text-align: center;

    h6 {
      color: $gray_dark2;
    }

    .metric-label {
      font-size: 12px;
      color: $gray;
    }
  }

  .select-button {
    transition: 200ms;
    display: flex;
    border: 1px solid $gray_light2;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    cursor: pointer;

    &:hover {
      border-color: $gray_light1;

      h6 {
        color: $gray_dark1;
      }
    }

    h6 {
      transition: 200ms;
      color: $gray;
      margin: auto;
      text-align: center;
    }
  }

  &.selected {
    .select-button {
      border-color: $primary;

      &:hover {
        border-color: $primary;

        h6 {
          color: $primary;
        }
      }

      h6 {
        color: $primary;
      }
    }
  }
}
