@import "../../shared/shared.themes.colors/colors.module.scss";

.TopicEditPage {
  .tabs-container {
    border-bottom: 1px solid $gray_light2;
    margin-bottom: 55px;
  }

  .tab-content {
    padding: 16px;
  }

  .Subtopics {
    .ListHeader,
    .ListItem {
      grid-template-columns: 0.4fr 0.2fr 0.2fr 0.2fr;
    }
  }
}
