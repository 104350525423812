@import "../../assets/scss/colors";
@import "../../assets/scss/vars";

@keyframes fadeInFromNone {
  0% {
    transform: scale(0.8);
    display: none;
    opacity: 0;
  }

  1% {
    display: flex;
    opacity: 0;
  }

  100% {
    display: flex;
    opacity: 1;
  }
}

@keyframes appearBottom {
  0% {
    transform: translateY(-5px);
  }

  100% {
    transform: translateY(0);
  }
}
.video-card-container {
  position: relative;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: $shadow_light;

  a {
    color: #000;
  }

  .icon {
    position: absolute;
    top: 0;
    left: 0;
    padding: 4px 6px;
    fill: #fff;
    border-bottom-right-radius: 4px;

    &.yt {
      background-color: $youtube;
    }
    &.fb {
      background-color: $facebook;
    }
    &.in {
      background-color: $instagram;
    }
    &.tw {
      background-color: $twitch;
    }
  }

  transition-duration: 0.2s;
  &:hover {
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.12);
  }
  @media (max-width: $mobile_break) {
    display: none;
    opacity: 0.01;
    &:nth-child(1) {
      transition: all 0.2s ease-in;
      z-index: 10;
      display: flex;
      flex: 1;
      opacity: 1;
      z-index: 10;
      transform-style: preserve-3d;
      animation: fadeInFromNone 0.3s ease-in;
      &::before {
        animation: appearBottom 0.2s ease-in;
        transform-style: preserve-3d;
        box-shadow: $shadow_light;
        display: flex;
        content: "";
        border-radius: 4px;
        position: absolute;
        top: 12%;
        left: 2.5%;
        width: 95%;
        height: 90%;
        background-color: white;
        z-index: -5000;
        transform: translateZ(-40px);
      }
    }
  }
}

.video-card-thumb {
  background-color: #000;
  position: relative;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  flex: 2;
  width: 100%;
  height: 180px;
  .img {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
  }

  span {
    color: white;
    background-color: rgba(80, 80, 80, 0.6);
    font-weight: 700;
    padding: 8px;
    position: absolute;
    bottom: 0;
    right: 15px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
}

.video-card-body {
  padding: 20px 10px;
  flex: 2;
  font-size: 16px;
  .video-card-tags {
    font-size: 14px;
    margin-top: 8px;
    .tags-title {
      font-weight: 600;
    }
    .tag {
      color: $post_gray;
    }
  }
}

.video-card-buttons {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #f2f3f6;

  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  .button {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition-duration: 0.2s;
    padding: 20px 0;
    cursor: pointer;
    &.confirm {
      border-left: 1px solid #f2f3f6;
      &:hover,
      &:focus {
        background-color: rgba(100, 228, 100, 0.55);
      }
    }
    &.cancel {
      &:hover,
      &:focus {
        background-color: rgba(255, 150, 150, 0.55);
      }
    }
    img {
      padding: 5px 0;
      @media (max-width: $mobile_break) {
        height: 30px;
        width: 30px;
      }
    }
  }
}

.video-card-metrics {
  justify-content: flex-end;
  border-top: 1px solid #a0a0a0;
  color: #a0a0a0;
  width: 100%;
  display: flex;
  padding: 8px 16px;
  margin-top: 8px;
  .metric {
    display: flex;
    margin-left: 16px;
    align-items: center;
    .Insights-ui__icon {
      width: 20px;
      margin-right: 8px;
    }
  }
}

.tooltip {
  max-width: 400px;
  span.tooltip-tag {
    font-weight: 700;
    display: inline-block;
    padding: 4px;
    margin: 4px;
    border: 1px solid white;
    border-radius: 4px;
  }
}
