@import '../../theme_vars.scss';

.Insights-ui__playercard {

  &.blank {
      .player-container {
          height: 540px;
          background-color: rgba(0,0,0,0.5);
      }
  }

  .player {
      width:100%;
  }
  .content-container {
      padding: 16px;
      background: #FFFFFF;
  }

  .title {
      margin:0 0 10px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;  
      overflow: hidden;
    }

  .title-and-creator {
      margin-bottom: 30px;
  }

  .creator-name {
      color: $brand1; 
      margin-left:5px;
  }

  .tags-container {
      color:#868A90;
      font-size: 12px;
  }

  .post-tag {
      display: inline-block;
      margin-left: 7px;
      &:after {
          content: ","
      }

      &:last-child {
          &:after {
              content: ""
          }
      }
  }

}