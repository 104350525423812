.Insights-ui__collapsed-multi-mark-container {
  margin: 16px 0;
  max-height: 415px;
  overflow-y: auto;
  padding: 1px 0px;

  .collapsed-element {
    cursor: pointer;
    margin-bottom: 12px;

    .mark-list {
      display: none;
    }

    &.open {
      .mark-list {
        display: block;
      }
    }

    &.hide {
      display: none;
    }

    &:last-child {
      margin: 0;
    }
  }
}
