@import "../../../../shared/shared.themes.colors/colors.module.scss";
@import "../../../../assets/scss/vars";

.TermsAndQueries {
  .queries-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: $spacing4;
    margin-top: $spacing7;

    .error-message,
    .empty-message,
    .Insights-ui__loading {
      grid-column: span 3;
    }
  }
}
