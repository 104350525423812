@import "../../shared/shared.themes.colors/colors.module.scss";

.container-card-creator {
  display: flex;
  box-sizing: border-box;
  padding: 20px 30px;

  .creator-text-container {
    align-self: center;

    h6 {
      color: $gray_dark1;
    }
  }

  .creator-thumbnail-container {
    position: relative;
    width: 52px;
    height: 52px;
    margin-right: 22px;
    .thumbnail-creator {
      border-radius: 50%;
      overflow: hidden;
      width: 52px;
      height: 52px;
    }
    .provider-badge-component {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      &.instagram {
        background-color: $instagram;
      }
      &.youtube {
        background-color: $youtube;
      }
      &.facebook {
        background-color: $facebook;
      }
      &.twitch {
        background-color: $twitch;
      }
      .Insights-ui__icon {
        fill: #fff;
        width: 10px;
      }
    }
  }
}
