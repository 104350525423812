@import "../../assets/scss/vars";
@import "../../assets/scss/colors";

.header {
  position: relative;

  width: 100%;
  z-index: 10;
  background-color: #fff;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
  }

  .logo {
    padding: 16px;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    .product-name {
      padding-left: 9px;
      margin-left: 9px;
      border-left: 1px solid #a0a0a0;
      color: #a0a0a0;
      display: inline-block;
      font-size: 1.6em;
    }
  }

  .nav {
    color: #868a90;
    display: flex;
    align-items: center;
    padding: 0 16px;
    a {
      padding: 10px 8px;
      &.active {
        color: $primary_green;
      }
    }

    a,
    .btn {
      color: #868a90;
      font-weight: normal;
    }
  }
}
