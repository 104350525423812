.wizard-page-new-container {
    
    display: flex;
    max-width: 1350px;
    margin: 72px auto 0;
    align-items: stretch;

    .split {
        display: block;
        text-align: center;
        margin:15px 0;
    }

    .Insights-ui__playercard {
        width: 950px;
    }

    .provider-player {
        height: 535px;
    }

    .wizard-container {
        margin-left: 20px;
    }

    p {
        margin: 20px 0;
    }

    .keyword-list {

        display: flex;
        flex-wrap: wrap;
        width: 100%;
        

        .keyword {   
            display:flex;
            align-items:center;
            border:1px solid rgba(0,0,0,0.2);
            border-radius:4px;
            padding: 4px 8px;
            margin: 0 8px 8px 0;
            cursor: pointer;
            text-transform: capitalize;

            font-size: 0.85em;

            .Insights-ui__icon {
                width: 15px;
                height: 15px;
                margin-left:5px;
            }

        }
    }

    textarea {
        min-height: 150px;
      }

}