@import "../../assets/scss/vars";

.home-page-container {
  color: #fff;

  .circle {
    margin: 20px;
  }
}

.home-page-loading {
  display: flex;
  height: 100vh;
  align-items: center;
}
