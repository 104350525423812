@import "../../shared/shared.themes.colors/colors.module.scss";

.container-toast {
  background: $primary;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 10000;
  padding: 12px 16px;
  font-size: 12px;
  color: #fff;
  min-height: 48px;
  display: flex;
  align-items: center;
  max-width: 403px;

  .toast-text {
    margin-right: 20px;
  }

  .toast-icon {
    fill: $base_color2;
    flex-shrink: 0;
    cursor: pointer;
  }

  .toast-actions {
    font-size: 14px;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 4px;

    &.toast-link {
      color: #fff;
    }

    &:hover {
      background: $primary_dark1;
    }
  }
}
