@import "../../assets/scss/vars";
@import "../../assets/scss/colors";

.status-bar {
  padding: 16px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  z-index: 3;
  background-color: #fff;

  .input-search-container {
    width: 100%;
    display: flex;
    align-items: center;
    .input-container {
      flex: 1;
    }
  }

  .first-level {
    margin-bottom: 16px;
    width: 100%;
  }

  .grid-container {
    display: flex;
    align-items: center;
  }

  .field-container {
    min-width: 200px;
    margin-right: 25px;
  }

  .topic-container {
    span,
    strong {
      display: inline-block;
      vertical-align: middle;
    }

    span {
      font-size: 1.2em;
      color: $post_gray;
    }

    strong {
      margin-left: 10px;
      font-size: 1.6em;
    }
  }

  .status-container {
    flex: 1;

    display: flex;
    align-items: flex-end;
    margin-top: 16px;

    span {
      padding: 4px;
      border: 1px solid #e4e4e4;
      border-radius: 4px;
      margin: 0 5px;
      font-size: 0.75em;
    }

    small {
      display: block;
      font-size: 0.65em;
      text-align: right;
    }
  }

  @media (max-width: $mobile_break) {
    background-color: #f2f3f6;
    box-shadow: none;
    flex-direction: column;
    padding: 12px 12px 0;

    .topic-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      strong {
        font-size: 2.6em;
        text-align: center;
      }
      span {
        font-size: 1.45em;
      }
    }

    .status-container {
      margin-top: 12px;
      span {
        font-weight: 700;
        font-size: 1.6em;
        color: $primary_blue;
      }
      small {
        display: none;
      }
    }
  }
}
