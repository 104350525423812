@import '../../shared/shared.themes.colors/colors.module.scss';


.Insights-ui__Modal {
  position: fixed;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: $shadow_light;
  &:focus {
    outline: none;
  }
}

.ReactModal__Overlay {
  opacity: 0.01;
  transition: opacity 200ms ease-in;
}
.ReactModal__Overlay--after-open {
 opacity: 1;
}
.ReactModal__Overlay--before-close {
 opacity: 0.01;
}
