@import "../../assets/scss/colors";

.loading-component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.fullpage {
    min-height: 100vh;
  }

  .loading-small {
    text-align: center;

    & > div {
      width: 10px;
      height: 10px;

      border-radius: 100%;
      display: inline-block;
      animation: loadBounce 1.4s infinite ease-in-out both;
    }

    .bounce1 {
      animation-delay: -0.32s;
      background-color: #007991;
    }
    .bounce2 {
      animation-delay: -0.16s;
      background-color: #2b99a0;
    }
    .bounce3 {
      background-color: #7cd6bb;
    }

    &.white {
      .bounce1 {
        background-color: white;
      }
      .bounce2 {
        background-color: white;
      }
      .bounce3 {
        background-color: white;
      }
    }

    &.topMargin {
      margin: 150px 0 0;
    }
  }
}

@keyframes loadBounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
