@import "../../../../shared/shared.themes.colors/colors.module.scss";
@import "../../../../assets/scss/vars";

.Approval {
  column-count: 3;
  column-gap: $spacing4;

  .QueryCard {
    display: inline-block;
    min-width: 100%;
    margin-bottom: $spacing4;
  }
}
