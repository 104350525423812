@import '../../shared/shared.themes.colors/colors.module.scss';

.Insights-ui__icon {
  height: 24px;
  width: 24px;

  &.color {
    &.in {
      fill: $instagram;
    }

    &.fb {
      fill: $facebook;
    }

    &.yt {
      fill: $youtube;
    }

    &.tw {
      fill: $twitch;
    }

    &.tt {
      fill: $tiktok;
    }
  }
}
