@import '../../shared/shared.themes.colors/colors.module.scss';
@import '../../shared/shared.themes.typograph/typograph.module.scss';

.Insights-ui__header {
  color: $primary_dark2;
  .tooltip-inline {
    margin: 0 0 0 8px;
    display: flex;
    font-weight: 400;
    max-width: 200px;
    & > div {
      display: flex;
    }
  }
  &.with-icon {
    display: flex;
    align-items: center;
  }
  &.variation-primary {
    color: $primary;
  }
}
