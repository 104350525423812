@import "../../theme_vars.scss";

.Insights-ui__postcard {
  cursor: pointer;
  transition: 0.2s ease-out;
  background-color: white;
  border-radius: 4px;

  &:hover {
    box-shadow: $shadow_light, 0 -2px 20px 4px rgba(0, 0, 0, 0.11);
    .image {
      .remove-video {
        opacity: 1;
      }
    }
  }
  .image {
    cursor: pointer;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: #000;
    overflow: hidden;
    height: 174px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    .remove-video {
      transition-duration: 0.2s;
      position: absolute;
      top: 12px;
      right: 12px;
      width: 24px;
      height: 24px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.01;
      border-radius: 50%;
      svg {
        width: 16px;
        height: 16px;
      }
      &:hover {
        svg {
          fill: #eb5757;
        }
      }
    }
  }
  .card-body {
    height: 126px;
    padding: 12px;
    border-bottom: 1px solid #f0f2f3;
    display: flex;
    flex-direction: column;
    span {
      color: #868a90;
    }
    .title {
      font-size: 16px;
      cursor: pointer;
    }
    .video-card-creator {
      padding: 8px 0;
      font-size: 14px;

      & > span {
        margin-right: 8px;
      }
    }
  }
  .card-footer {
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .video-card-detail {
      display: flex;
      font-size: 14px;
      margin: 0 12px;
      align-items: center;
      color: $text_gray;
      span {
        padding-left: 6px;
      }
    }
    .video-statistics {
      display: flex;
    }
    .provider {
      margin: 12px;
    }
  }

  .provider {
    height: 26px;
    width: 26px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid #fff;
    &.youtube {
      background-color: $youtube;
    }
    &.facebook {
      background-color: $facebook;
    }
    &.instagram {
      background-color: $instagram;
    }
    &.twitch {
      background-color: $twitch;
    }
    .icon {
      height: 16px;
      width: 16px;
      fill: #ffffff;
    }
  }
}
