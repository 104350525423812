@import "../../shared/shared.themes.colors/colors.module.scss";

.SimplePostGrid {
  .posts-grid-header {
    margin-bottom: 20px;
  }

  > .Insights-ui__loading .loading {
    min-height: 50px;
  }

  .posts-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    grid-gap: 16px;

    .Insights-ui__loading {
      grid-column: 1/-1;
    }
  }
}
