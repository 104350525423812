@import "../../shared/shared.themes.colors/colors.module.scss";

.ListHeader {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  margin-bottom: 12px;

  .header-container {
    display: flex;
    align-items: center;
    padding: 0 20px;

    .label {
      font-size: 12px;
      font-weight: bold;
      color: $gray_dark1;
      margin-right: 8px;
    }

    .header-tooltip-icon {
      transform: translateY(1px);
    }
  }
}
