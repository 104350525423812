@import "../../shared/shared.themes.colors/colors.module.scss";

.SubtopicsPage {
  .page-header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 20px;

    .create-new-button {
      flex-shrink: 0;

      button {
        margin: 0 16px 0 0;
      }
    }

    .subtopic-filters {
      flex-grow: 1;
      display: grid;
      grid-gap: 16px;
      grid-template-columns: 1fr 180px 260px 260px;

      form {
        margin-top: auto;

        input {
          height: 38px;
          margin: 0;
        }
      }
    }
  }

  .page-content {
    padding: 20px;
  }

  .subtopics-list-header,
  .subtopics-item {
    grid-template-columns: 0.4fr 0.3fr 0.3fr 150px 150px 150px;
  }
}
