@import "../../assets/scss/vars";

.video-grid-container {
  background-color: #f2f3f6;
  height: 100%;
  width: 100%;
  @media (max-width: $mobile_break) {
    display: flex;
    flex: 1;
  }
}

.video-grid-wrapper {
  width: 100%;
  height: 100%;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-template-rows: repeat(auto-fill, minmax(400px, 1fr));
  place-items: stretch;
  grid-gap: 20px;
  @media (max-width: $mobile_break) {
    height: unset;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}
