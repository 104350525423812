/*** Colors ***/
// Really Base Color
$base_color1: #000;
$base_color2: #fff;

// Main Colors
$primary: #6324c6;
$primary_light2: lighten($primary, 20%);
$primary_light1: lighten($primary, 10%);
$primary_dark1: darken($primary, 15%);
$primary_dark2: darken($primary, 35%);

$highlight: #ffb800;
$highlight_light2: lighten($highlight, 20%);
$highlight_light1: lighten($highlight, 10%);
$highlight_dark1: darken($highlight, 15%);
$highlight_dark2: darken($highlight, 35%);

// Default Background
$background: #f5f7fb;

// Gray Scale
$gray: #a7b0be;
$gray_light2: lighten($gray, 20%);
$gray_light1: lighten($gray, 10%);
$gray_dark1: darken($gray, 15%);
$gray_dark2: darken($gray, 35%);
$gray_insights3: #f1f2fa;
$gray_insights3_hover: #e7e8f2;

// Charts
$graph_item_1: #f7ca00;
$graph_item_2: #7e2fbf;
$graph_item_3: #3bba18;
$graph_item_4: #ef377a;
$graph_item_5: #005dff;

// Feedback
$success: #01ada6;
$warning: #ffb800;
$danger: #e92151;

// Providers
$youtube: #e33030;
$facebook: #4267b2;
$instagram: #fcaf45;
$twitch: #874ff6;
$tiktok: #000000;

// Others
$trend: #ff7a41;
$hover_blue: #d2dcff;

// Shadows
$shadow_light: 3px 3px 12px rgba(0, 0, 0, 0.08),
  0 0 6px 1px rgba(32, 32, 32, 0.02);
$shadow_popup: 3px 3px 8px rgba(0, 0, 0, 0.16);

/*** Typography  ***/
$sans: 'Muli', Arial, sans-serif;
