@import "../../shared/shared.themes.colors/colors.module.scss";

.Dropdown {
  display: inline-flex;
  align-items: center;
  gap: 8px;

  .dropdown-label {
    color: #a7b0be;
    font-size: 14px;
  }

  .dropdown-tooltip-container {
    > div:first-child {
      width: 18px;
      height: 18px;
    }
  }

  .custom-single-value {
    display: inline-flex;
    align-items: center;

    svg {
      width: 7px;
      margin: 1px 0 0 5px;
    }
  }
}
