@import "../../../../shared/shared.themes.colors/colors.module.scss";
@import "../../../../assets/scss/vars";

.Status {
  .ListHeader,
  .ListItem {
    grid-template-columns: 2fr 2.9fr 2.9fr 1.4fr 0.8fr;
  }

  .ListItem {
    cursor: pointer;
    padding: $spacing2 0;

    &:not(:last-child) {
      border-bottom: 1px solid $gray_light2;
    }

    .status-username {
      font-weight: bold;
    }

    .status-topic {
      margin-top: $spacing2;
      font-size: 0.8rem;
    }
  }

  .QueryStatus {
    &.rejected svg path {
      stroke: $danger !important;
    }

    &.approved svg path {
      stroke: $success !important;
    }
  }
}
