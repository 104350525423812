@import '../../shared/shared.themes.colors/colors.module.scss';

.Insights-ui__loading {
  display: block;
  margin: 20px auto;

  .loading {
    text-align: center;
    &.small {
      & > div {
        width: 10px;
        height: 10px;
      }
    }

    &.medium {
      & > div {
        width: 25px;
        height: 25px;
      }
    }

    &.large {
      & > div {
        width: 35px;
        height: 35px;
      }
    }

    & > div {
      border-radius: 100%;
      display: inline-block;
      animation: loadBounce 1.4s infinite ease-in-out both;
    }

    .bounce1 {
      animation-delay: -0.32s;
      background-color: $primary_dark1;
    }
    .bounce2 {
      animation-delay: -0.16s;
      background-color: $primary;
    }
    .bounce3 {
      background-color: $primary_light2;
    }

    &.white {
      .bounce1 {
        background-color: white;
      }
      .bounce2 {
        background-color: white;
      }
      .bounce3 {
        background-color: white;
      }
    }

    &.topMargin {
      margin: 150px 0 0;
    }
  }

  @keyframes loadBounce {
    0%,
    80%,
    100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
}
