@import '../../shared/shared.themes.colors/colors.module.scss';

.Insights-ui__input {
  appearance: none;
  input, textarea {
    border: 1px solid $gray_light2;
    border-radius: 4px;
    padding: 10px;
    font-size: 1rem;
    width: 100%;
    margin-bottom: 6px;
    &:focus {
      outline: none;
      border-color: $primary;
    }
  }
}
