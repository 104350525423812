@import "../../shared/shared.themes.colors/colors.module.scss";

.ListItem {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  border-radius: 4px;

  &:hover {
    background: transparentize($primary_light2, 0.9);
  }

  .column-container {
    &.clickable {
      cursor: pointer;

      &:hover {
        background: transparentize($primary_light2, 0.9);
      }
    }

    .column-tooltip-container,
    .label {
      padding: 20px;
    }

    .column-tooltip-container {
      .__react_component_tooltip {
        max-width: 360px;
      }
    }
  }
}
