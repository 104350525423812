@import "../../assets/scss/vars";

.tagging-page-loading {
  background-color: #f2f3f6;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;

  @media (min-width: $mobile_break) {
    min-height: calc(100vh - 117px);
  }

  button {
    background-color: #029c96;
    color: #fff;
    padding: 16px 25px;
    border-radius: 3px;
  }
}
