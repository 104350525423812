@import "./assets/scss/normalize";
@import "./shared/shared.themes.colors/colors.module.scss";
@import "./assets/scss/colors";
@import "./assets/scss/vars";

html {
  box-sizing: border-box;
}

*,
*::after,
*::before {
  box-sizing: inherit;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

#root {
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: $mobile_break) {
    min-height: 100vh;
  }
}

body {
  font-family: "Muli", Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  font-variant-ligatures: none;
  text-rendering: geometricPrecision;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.87);
  background-color: $base_color2;
  overflow-y: scroll;

  @media only screen and (max-width: $mobile_break) {
    font-size: 12px;
  }
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  color: inherit;
}

a {
  text-decoration: none;
}

*:focus {
  outline: none;
}

img {
  max-width: 100%;
}

.btn {
  background-color: transparent;
  border: none;
  cursor: pointer;

  &.btn {
    padding: 16px 25px;
    border-radius: 3px;
  }

  &.green {
    background-color: $primary_green;
    color: #fff;
  }

  &.grey {
    background-color: #e4e4e4;
  }

  &.block {
    display: block;
    margin: auto;
    flex: 1;
    width: 100%;
  }
}

.color {
  &.green {
    color: $primary_green;
  }

  &.purple {
    color: $purple;
  }

  &.dark-purple {
    color: $dark_purple;
  }
}

.heading {
  text-transform: capitalize;
  &.light {
    font-weight: normal;
  }
}

.sub-heading {
  display: block;
}

.always-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
}

.all-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-container {
  background-color: #f2f3f6;
  min-height: 100vh;
}

.link-color {
  color: $primary_green;
}

.bg-purple {
  background: radial-gradient(
    1092.07px at -6.82% 4.5%,
    #32156a 0%,
    #17023f 100%
  );
  color: #fff;
}

.sidebar-widget {
  margin-top: 16px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 1px 2px 5px 0 rgba(93, 119, 132, 0.5);
  padding: 16px;
  h4 {
    margin-top: 0;
    margin-bottom: 8px;
    color: #494b4f;
    font-weight: bold;
    font-size: 1.125em;
    .subtitle {
      display: block;
      font-size: 0.7em;
      color: #858a91;
    }
  }
}

.clamp {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;

  &.one {
    -webkit-line-clamp: 1;
  }
  &.two {
    -webkit-line-clamp: 2;
  }
  &.three {
    -webkit-line-clamp: 3;
  }
  &.four {
    -webkit-line-clamp: 4;
  }
}

.element {
  &.circle {
    border-radius: 50%;
  }

  &.purple {
    border: 1px solid $purple;
  }
}

.ReactModal__Body--open {
  overflow: hidden;
}
