@import "../../shared/shared.themes.colors/colors.module.scss";

.SubtopicDeleteModal {
  max-width: 340px;
  padding: 40px;

  .title {
    margin-bottom: 16px;
  }

  .buttons {
    display: flex;
    margin-top: 32px;

    .Insights-ui__btn {
      margin: 0;

      &:first-child {
        margin-right: 16px;
      }
    }
  }
}
