@import '../../shared/shared.themes.colors/colors.module.scss';

.Insights-ui__link {
  text-decoration: none;
  color: $primary;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
