@import '../../shared/shared.themes.colors/colors.module.scss';

.Insights-ui__group-mark-container {
  .mark-list {
    margin: 16px 0 0 16px;
  }

  .option-element {
    display: flex;
    align-items: center;
    color: rgba(73, 75, 78, 0.9);
    margin-bottom: 12px;
    cursor: pointer;

    .mark {
      border: 1px solid #868a90;
      width: 11px;
      height: 11px;
      border-radius: 2px;
      margin-right: 8px;
    }

    &.active {
      .mark {
        border-color: $primary;
        background-color: $primary;
      }
    }
  }

  &.hide {
    display: none;
  }

  &.radio {
    .mark {
      border-radius: 50%;
    }
  }
}
