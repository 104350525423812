@import "../../shared/shared.themes.colors/colors.module.scss";

.SimplePostCard {
  display: flex;
  flex-direction: column;

  .thumbnail {
    img {
      border-radius: 4px 4px 0 0;
      display: block;
      width: 100%;
      height: 170px;
      object-fit: cover;
    }
  }

  .post-header,
  .post-footer {
    padding: 12px;
  }

  .post-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;

    .post-title {
      .post-link {
        color: $base_color1;

        &:hover {
          color: $gray_dark2;
        }
      }

      .__react_component_tooltip {
        max-width: 270px;
        margin-bottom: 10px;
      }
    }

    .creator-title {
      font-size: 14px;
      color: $gray_dark1;

      span {
        color: $gray_dark2;
      }
    }

    .topics {
      font-size: 12px;
      color: $primary;

      span {
        color: $gray;
      }
    }

    .published {
      margin-top: 16px;
      align-self: flex-end;
      font-size: 12px;
      color: $gray;
    }

    * + * {
      margin-top: 8px;
    }
  }

  .post-footer {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $gray_light2;
    color: $gray_dark1;

    .provider {
      height: 26px;
      width: 26px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border-radius: 50%;
      border: 1px solid $base_color2;

      &.yt {
        background-color: $youtube;
      }
      &.fb {
        background-color: $facebook;
      }
      &.in {
        background-color: $instagram;
      }
      &.tw {
        background-color: $twitch;
      }

      .Insights-ui__icon {
        height: 16px;
        width: 16px;
        fill: $base_color2;
      }
    }

    .metrics {
      display: flex;
      font-size: 14px;

      p:last-child {
        margin-left: 12px;
      }
    }
  }
}
