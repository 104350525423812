@import "../../shared/shared.themes.colors/colors.module.scss";
@import "../../assets/scss/vars";

.QueryCard {
  border: 1px solid $gray_light1;

  &.Insights-ui__card {
    border-radius: $border-radius;
  }

  .query-card-title {
    padding: $spacing4;
  }

  .query-card-query {
    padding: 0 $spacing4 $spacing4;
  }

  .query-card-actions {
    display: flex;

    .query-card-button {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      background: unset;
      border: none;
      border-top: 1px solid $gray_light1;
      padding: $spacing4;
      cursor: pointer;
      transition: $transition_duration;

      &:hover {
        background-color: $gray_light2;
      }

      svg {
        margin-right: $spacing3;
      }

      &:first-child {
        border-right: 1px solid $gray_light1;

        svg path {
          stroke: $danger !important;
        }
      }

      &:last-child {
        svg path {
          stroke: $success !important;
        }
      }

      &:disabled {
        cursor: not-allowed;

        &:hover {
          background-color: unset;
        }
      }
    }
  }
}
