$mobile_break: 768px;

/*
 * spacing
 */
$spacing1: 4px;
$spacing2: 8px;
$spacing3: 16px;
$spacing4: 20px;
$spacing5: 24px;
$spacing6: 28px;
$spacing7: 32px;
$spacing8: 36px;
$spacing9: 40px;
$spacing10: 44px;
$spacing11: 48px;
$spacing12: 56px;
$spacing13: 64px;
$spacing14: 72px;

/*
 * colors
 */
$hover_blue: #d2dcff;

/*
 * units
 */
$border_radius: 8px;
$transition_duration: 200ms;
