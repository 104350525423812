@import '../../theme_vars.scss';

.select-group {
  
  position: relative;

  .select-label {
    font-size: 12px;
    color: rgba(73, 75, 78, 0.9);
  }
  .filter {
    margin-left: 0;
  }
  .select-icon-group {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    &:focus-within {
      border-color: $brand1;
    }
    .filter {
      width: 100%;
      & > div {
        border: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    .select-icon {
      padding-left: 6px;
      height: 100%;
      min-width: 36px;
    }
  }

  //fix react-bug
  div[class*="-menu"]{
    bottom: auto !important;
  }

}
