@import "../../shared/shared.themes.colors/colors.module.scss";

.thumbnail-component-container {
  position: relative;

  img {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
  }

  .Insights-ui__icon {
    width: 100%;
    height: 100%;
    display: block;
  }

  .thumbnail-no-image {
    max-height: 173px;
  }
}
