@import "../../shared/shared.themes.colors/colors.module.scss";
@import "../../assets/scss/vars";

.QueryEditModal {
  width: 420px;

  .Insights-ui__btn {
    margin: 0;
  }
  .Textarea {
    margin: $spacing6 0 $spacing3;
  }

  .edit-actions {
    display: flex;
    justify-content: flex-end;

    button:first-child {
      margin-right: $spacing4;
    }
  }

  .edit-error {
    margin-top: $spacing4;
  }
}
