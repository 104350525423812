@import "../../shared/shared.themes.colors/colors.module.scss";

.SubtopicSuccessModal {
  max-width: 270px;
  padding: 40px;

  .title {
    margin-bottom: 16px;
  }

  .buttons {
    display: flex;
    margin-top: 32px;

    .Insights-ui__btn {
      margin: 0;
      width: 100%;
      justify-content: center;
    }
  }
}
