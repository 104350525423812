@import url("https://fonts.googleapis.com/css?family=Muli:300,400,600,700");

/*** Colors ***/

// Brand Colors
$brand1: #029c96;
$brand1_disabled: #b9dddb;
$brand1_hover: #048681;

$brand2: #765697;
$brand2_disabled: rgb(204, 182, 230);
$brand2_hover: #593193;

// Function colors
$warning: #fbae42;
$info: #007bff;
$danger: #ff0000;

// Grayscale
$light_gray1: #e5e5e5;
$light_gray2: #d3d6db;
$light_gray3: #b5b9bf;

$dark_gray1: #868a90;
$dark_gray2: #494b4e;
$dark_gray3: #323435;

$black: #000;
$text_black: #494b4f;
$text_gray: #000000de;

// Shadows
$shadow_light: 3px 3px 12px rgba(0, 0, 0, 0.08),
  0 0 6px 1px rgba(32, 32, 32, 0.02);
$shadow_popup: 3px 3px 8px rgba(0, 0, 0, 0.16);

//Providers
$youtube: #ff0000;
$facebook: #3b5999;
$instagram: #fcaf45;
$twitch: #874ff6;

/*** ***/

/*** Typography  ***/

$sans: "Muli", Arial, sans-serif;
