.SubtopicCreatePage {
  .status-bar {
    justify-content: baseline;
    align-items: baseline;
    flex-direction: column;

    .controls {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .Insights-ui__btn,
      .Insights-ui__loading {
        margin: 0;
      }

      .subtopic-controls {
        display: flex;
        align-items: center;

        .sample-quality {
          display: flex;
          margin: 2px 64px 0 16px;
          flex-direction: column;
          font-weight: bold;
          text-transform: capitalize;
          font-size: 24px;

          span {
            color: #7b899d;
            font-size: 16px;
            margin-left: 16px;
          }
        }
      }
    }

    .filters {
      display: flex;

      .select-group,
      .input-container {
        width: 260px;

        &:not(:first-child) {
          margin-left: 16px;
        }
      }
    }

    .input-term {
      margin-top: 16px;
      width: 100%;
    }
  }

  .page-results {
    .count {
      padding: 16px;
    }

    .video-grid-wrapper {
      padding: 22px;
      grid-template-rows: repeat(auto-fill, 400px);

      .video-card-container {
        height: 400px;
      }

      .video-card-body {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        height: 220px;
        padding: 0;

        .video-card-tags {
          flex-grow: 1;
          max-height: 180px;
          overflow: hidden;
          padding: 0 16px;
        }

        a {
          padding: 16px 16px 0;
        }
      }

      .loading-videos-grid {
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        grid-column: 1 / -1;
      }
    }
  }
}
