@import "../../shared/shared.themes.colors/colors.module.scss";
@import "../../assets/scss/vars";

.HighlightedQuery {
  color: $primary;

  .muted {
    color: $gray_dark1;
  }
}
