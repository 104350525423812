@import "../../shared/shared.themes.colors/colors.module.scss";
@import "../../assets/scss/vars";

.CardWithOptions {
  position: relative;
  border-radius: 8px;
  border: 1px solid $gray_light1;
  transition: 200ms;
  padding: 22px 28px;

  .card-options {
    position: absolute;
    top: 17px;
    right: 12px;
    width: 14px;
    height: 14px;

    .card-options-button {
      visibility: hidden;
      opacity: 0;
      display: block;
      width: 14px;
      height: 14px;
      cursor: pointer;
      fill: $gray_dark1;
      transition: 200ms;

      &:hover + .card-options-list-wrapper {
        visibility: visible;
        opacity: 1;
      }
    }

    &:hover .card-options-button {
      fill: $gray;
    }
  }

  .card-options-list-wrapper {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    padding-top: 12px;
    right: -10px;
    transition: 200ms;
    z-index: 10;

    &:hover {
      visibility: visible;
      opacity: 1;
    }

    .card-options-list {
      list-style-type: none;
      padding: 0;
      margin: 0;
      box-shadow: $shadow_light;
      background-color: $base-color2;
      border-radius: 8px;
      border: 1px solid $gray_light1;
      overflow: hidden;
    }

    .card-options-item {
      transition: 200ms;
      padding: 10px 16px;
      display: flex;
      align-items: center;
      color: $gray_dark2;
      font-size: 12px;
      cursor: pointer;

      &:hover {
        background-color: $hover_blue;
      }

      &:active {
        background-color: lighten($hover_blue, 4%);
      }

      .card-options-icon {
        width: 10px;
        margin-right: 10px;
        fill: $gray_dark2;
      }
    }
  }

  &:hover {
    border: 1px solid $primary;
    box-shadow: $shadow_light;

    .card-options-button {
      visibility: visible;
      opacity: 1;
    }
  }

  &.disabled {
    opacity: 0.5;
  }
}
